<template>
    <div>
        <b-button-toolbar class="mb-3">
            <b-button-group class="ml-auto">
                <b-button to="../list">
                    <b-icon icon="list"/>
                    Список
                </b-button>
                <b-button to="view">
                    <b-icon icon="info"/>
                    Просмотреть
                </b-button>
                <b-button to="../add">
                    <b-icon icon="plus"/>
                    Добавить
                </b-button>
                <b-button @click="doDelete()">
                    <b-icon icon="trash"/>
                    Удалить
                </b-button>
            </b-button-group>
        </b-button-toolbar>
        <b-overlay :show="!item || request">
            <b-form
                autocomplete="off"
                @submit="submit"
            >
                <b-form-group
                    label="Email"
                    label-for="email"
                >
                    <b-form-input
                        id="email"
                        v-model="form.email"
                        type="text"
                        placeholder="Введите email"
                        required
                        :state="errors.email ? false : null"
                        autocomplete="off"
                    />
                    <b-form-invalid-feedback>
                        <ul>
                            <li
                                v-for="error in errors.email"
                                :key="error"
                            >
                                {{ error }}
                            </li>
                        </ul>
                    </b-form-invalid-feedback>
                </b-form-group>
                <b-form-group label="Сменить пароль?">
                    <b-form-checkbox v-model="form.change_password"/>
                </b-form-group>
                <b-form-group
                    v-if="form.change_password"
                    label="Пароль"
                    label-for="password"
                >
                    <b-form-input
                        id="password"
                        v-model="form.password"
                        type="password"
                        placeholder="Введите пароль"
                        :state="errors.password ? false : null"
                        autocomplete="new-password"
                    />
                    <b-form-invalid-feedback>
                        <ul>
                            <li
                                v-for="error in errors.password"
                                :key="error"
                            >
                                {{ error }}
                            </li>
                        </ul>
                    </b-form-invalid-feedback>
                </b-form-group>

                <b-button
                    type="submit"
                    variant="primary"
                >
                    Сохранить
                </b-button>
            </b-form>
        </b-overlay>
    </div>
</template>

<script>
export default {
    data() {
        return {
            item: null,
            form: {
                id: null,
                email: null,
                change_password: false,
                password: null,
            },
            errors: {},
            request: false,
        };
    },
    mounted() {
        this.refreshData();
    },
    methods: {
        submit(event) {
            let id = this.$route.params.id;

            if (!id || isNaN(id)) {
                this.$alertify.error("Некорректный ID");
                return;
            }
            id = parseInt(id);
            event.preventDefault();

            if (this.request) {
                return;
            }

            this.request = true;

            this.axios.patch(`/admin_api/admins/${id}`, {...this.form})
                .then(async () => {
                    this.$alertify.success("Данные успешно изменены");
                    this.$router.push({path: "view"});
                })
                .catch(async ({response}) => {
                    if (response.status === 422) {
                        this.errors = response.data.errors;
                    }
                    this.$alertify.error(response.data.message);
                })
                .finally(async () => {
                    this.request = false;
                });

        },
        refreshData() {
            let id = this.$route.params.id;

            if (!id || isNaN(id)) {
                this.$alertify.error("Некорректный ID");
                return;
            }
            id = parseInt(id);

            this.axios.get(`/admin_api/admins/${id}`).then(async ({data}) => {
                this.item = data;
                for (let i in this.item) {
                    // eslint-disable-next-line no-prototype-builtins
                    if (this.form.hasOwnProperty(i) && this.item.hasOwnProperty(i)) {
                        // noinspection JSUnfilteredForInLoop
                        this.form[i] = this.item[i];
                    }
                }
            });
        },
        doDelete() {
            let id = this.$route.params.id;

            if (!id || isNaN(id)) {
                this.$alertify.error("Некорректный ID");
                return;
            }
            id = parseInt(id);

            this.axios.delete(`/admin_api/admins/${id}`).then(async () => {
                this.$alertify.success("Данные удалены");
                this.$router.push("../list");
            });
        },
    },
};
</script>
